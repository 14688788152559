<template>
  <c-box>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Problem
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          error-border-color="red.300"
          placeholder="Masukkan Problem"
          height="62px"
          v-model="value.problem"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Etiology
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          error-border-color="red.300"
          placeholder="Masukkan Etiology"
          height="62px"
          v-model="value.etiology"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Sign & Symptoms
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          error-border-color="red.300"
          placeholder="Masukkan Sign & Symptoms"
          height="62px"
          v-model="value.signSymptom"
        />
      </c-input-group>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: "PrimaryNutritionDiagnosisForm",
  props: ["value"],
}
</script>