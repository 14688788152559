<template>
  <c-box>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Jenis olahraga & durasi/Exercise type & duration
      </c-form-label>
      <textarea
        rows="8"
        placeholder="Masukkan Jenis olahraga & durasi/Exercise type & duration"
        v-chakra="{
          border: '1px solid #C4C4C4',
          padding: '18px',
          width: '100%',
          borderRadius: '6px',
          height: '180px',
          '&:focus, &:focus-visible': {
            outline: 'unset',
          },
        }"
        v-model="value.excercise"
      ></textarea>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Tipikal keaktifan pada hari kerja (weekdays)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Tipikal keaktifan pada hari kerja (weekdays)"
          height="62px"
          v-model="value.weekdaysTypicalActivity"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Tipikal keaktifan pada hari libur dan akhir pekan
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Tipikal keaktifan pada hari libur dan akhir pekan"
          height="62px"
          v-model="value.weekendTypicalActivity"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Presentase Aktivitas Fisik
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.activityPercentage"
          placeholder="Masukan Presentase Aktivitas Fisik"
          height="62px"
        >
          <option value="0">0%</option>
          <option value="10">10%</option>
          <option value="30">30%</option>
          <option value="40">40%</option>
          <option value="50">50%</option>
          <option value="60">60%</option>
          <option value="70">70%</option>
          <option value="80">80%</option>
          <option value="90">90%</option>
        </c-select>
      </c-input-group>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: "PhysicalActivityHistoryForm",
  props: ["value"],
}
</script>