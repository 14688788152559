<template>
  <c-box>
    <template v-for="(step, index) in steps">
      <CardStep
        v-if="stepIndex === index && !isSuccess"
        :key="index"
        :current="index + 1"
        :total="steps.length"
        :is-prev="index !== 0"
        :is-next="true"
        @prev="stepIndex--"
        @next="nextForm"
      >
        <c-text font-weight="700" mb="42px"> {{ step.title }} </c-text>
        <ProfileForm v-if="step.type === 'profile'" v-model="profile" />
        <NutritionAssessment
          v-if="step.type === 'nutritionAssessment'"
          :age="profile.age"
          :gender="gender ? gender : 'female'"
          v-model="nutritionAssessment"
        />
        <PhysicalActivityHistory
          v-if="step.type === 'physicalActivityHistory'"
          v-model="physicalActivityHistory"
        />
        <CalorieMacroNutrientIntake
          v-if="step.type === 'calorieMacroNutrientIntake'"
          :energy-bassal="nutritionAssessment.basalEnergyNeeds"
          :activity-percentage="physicalActivityHistory.activityPercentage"
          :addition-maternal-calorie="
            nutritionAssessment.maternalCalorieAddition
          "
          :weight="nutritionAssessment.weight"
          :calorie-deficit="calorieDeficit"
          v-model="calorieMacroNutrientIntake"
        />
        <FoodNutritionHistory
          v-if="step.type === 'foodNutritionHistory'"
          v-model="foodNutritionHistory"
        />
        <BodyCompositionMeasure
          v-if="step.type === 'bodyCompositionMeasure'"
          v-model="bodyCompositionMeasure"
        />
        <BiochemicalData
          v-if="step.type === 'biochemicalData'"
          v-model="biochemicalData"
        />
        <PrimaryNutritionDiagnosis
          v-if="step.type === 'primaryNutritionDiagnosis'"
          v-model="primaryNutritionDiagnosis"
        />
        <NutritionIntervention
          v-if="step.type === 'nutritionIntervention'"
          v-model="nutritionIntervention"
        />
        <NutritionMonitoring
          v-if="step.type === 'nutritionMonitoring'"
          v-model="nutritionMonitoring"
        />
      </CardStep>
    </template>
    <c-flex v-if="isSuccess" flex-dir="column" align="center">
      <c-heading
        as="h2"
        fontSize="36px"
        fontWeight="600"
        color="black.900"
        marginBottom="54px"
        textAlign="center"
        lineHeight="54px"
      >
        Submit Berhasil!
      </c-heading>
      <c-image
        :src="require('@/assets/paid.png')"
        mx="auto"
        marginBottom="30px"
      />
      <c-text
        marginBottom="20px"
        color="gray.900"
        :fontSize="['14px', '24px']"
        :lineHeight="['21px', '36px']"
        fontFamily="Roboto"
        v-text="'Adime Note kamu berhasil di-submit'"
      >
      </c-text>
      <c-button
        as="router-link"
        :to="{ name: 'nutri.adime-note' }"
        fontWeight="700"
        right-icon="arrow-forward"
        color="brand.900"
        variant="outline"
        border-color="brand.900"
        border-radius="200px"
        py="10px"
        px="30px"
      >
        Kembali ke ADIME Note
      </c-button>
    </c-flex>
  </c-box>
</template>

<script>
import CardStep from "@/views/nutritionists/follow-up-notes/card-step"
import _ from "lodash"
import { mapActions, mapState } from "vuex"
import StepData from "../steps.js"
import ProfileForm from "./profile.vue"
import NutritionAssessment from "./nutrition-assessment.vue"
import PhysicalActivityHistory from "./physical-activity-history.vue"
import CalorieMacroNutrientIntake from "./calorie-macro-nutrient-intake.vue"
import FoodNutritionHistory from "./food-nutrition-history.vue"
import BodyCompositionMeasure from "./body-composition-measure.vue"
import BiochemicalData from "./biochemical-data.vue"
import PrimaryNutritionDiagnosis from "./primary-nutrition-diagnosis.vue"
import NutritionIntervention from "./nutrition-intervention.vue"
import NutritionMonitoring from "./nutrition-monitoring.vue"

export default {
  name: "AdimeNoteForm",
  components: {
    CardStep,
    ProfileForm,
    NutritionAssessment,
    PhysicalActivityHistory,
    CalorieMacroNutrientIntake,
    FoodNutritionHistory,
    BodyCompositionMeasure,
    BiochemicalData,
    PrimaryNutritionDiagnosis,
    NutritionIntervention,
    NutritionMonitoring,
  },
  data() {
    return {
      stepIndex: 0,
      steps: StepData.steps,

      isDraft: true,
      profile: {
        firstName: "",
        lastName: "",
        middleName: "",
        age: 0,
        referralReason: "",
        mealPlanObjective: "",
      },
      nutritionAssessment: {
        weight: 0,
        height: 0,
        bodyMassIndex: 0,
        minIdealWeight: 0,
        maxIdealWeight: 0,
        nutritionalStatus: "",
        basalEnergyNeeds: 0,
        maternalCondition: "",
        maternalCalorieAddition: 0,
        maintain: "",
        improve: "",
      },
      physicalActivityHistory: {
        excercise: [
          {
            type: "",
            duration: "",
          },
          {
            type: "",
            duration: "",
          },
        ],
        weekdaysTypicalActivity: "",
        weekendTypicalActivity: "",
        activityPercentage: "",
      },
      calorieMacroNutrientIntake: {
        energyIntake: "",
        energyNeeds: "",
        energyAdequancy: "",
        currentProteinIntake: "",
        proteinNeeds: "",
        proteinIntakeNeeds: "",
        proteinNeedsConstant: "",
        proteinAdequancy: "",
        currentFatIntake: "",
        fatNeedsConstant: "",
        fatIntakeNeeds: "",
        fatAdequancy: "",
        currentCarbohydrateIntake: "",
        carbohydrateIntakeNeeds: "",
        carbohydrateAdequancy: "",
        fiberIntake: "",
        fiberNeeds: "",
        fiberAdequancy: "",
      },
      foodNutritionHistory: {
        foodPreference: "",
        foodAllergies: "",
        fluidConsumption: "",
        personServeFood: "",
        additionalNote: "",
      },
      bodyCompositionMeasure: {
        waistSize: "",
        waistSizeStandard: "",
        waistSizeConclusion: "",
        fat: "",
        fatStandard: "",
        fatConclusion: "",
        visceralFat: "",
        visceralFatStandard: "",
        visceralFatConclusion: "",
        muscleMass: "",
        muscleMassStandard: "",
        muscleMassConclusion: "",
      },
      biochemicalData: {
        bloodSugar: 0,
        bloodSugarStandard: 0,
        bloodSugarConclusion: "",
        colestrol: 0,
        colestrolStandard: 0,
        colestrolConclusion: "",
        colestrolHDL: 0,
        colestrolHDLStandard: 0,
        colestrolHDLConclusion: "",
        colestrolLDL: 0,
        colestrolLDLStandard: 0,
        colestrolLDLConclusion: "",
        uricAcid: 0,
        uricAcidStandard: 0,
        uricAcidConclusion: "",
        nutritionFocusedPhysicalFinding: "",
        clientHistory: "",
        others: [
          {
            field: "",
            value: "",
          },
        ],
      },
      primaryNutritionDiagnosis: {
        problem: "",
        etiology: "",
        signSymptom: "",
      },
      nutritionIntervention: {
        nutritionPrescription: "",
        goal: "",
      },
      nutritionMonitoring: {
        checkParts: [],
        indicators: [],
      },
      calorieDeficit: 0,
      isSuccess: false,
    }
  },
  computed: {
    ...mapState({
      item: (s) => s.nutriAdimeNote.adimeNote,
      client: (s) => s.clients.data,
    }),
    id() {
      return this.$route.query?.id
    },
    gender() {
      console.log("CLIENT!!!", this.client)
      return this.client[this.clientId].gender
    },
  },
  watch: {
    item: {
      immediate: true,
      handler(val) {
        if (_.isEmpty(val)) return

        if (val) {
          this.isDraft = val.isDraft

          if (!_.isEmpty(val.profile)) {
            this.profile = val.profile
          }
          if (!_.isEmpty(val.nutritionAssessment)) {
            this.nutritionAssessment = val.nutritionAssessment
          }
          if (!_.isEmpty(val.physicalActivityHistory)) {
            this.physicalActivityHistory = val.physicalActivityHistory
          }
          if (!_.isEmpty(val.calorieMacroNutrientIntake)) {
            this.calorieMacroNutrientIntake = val.calorieMacroNutrientIntake
          }
          if (!_.isEmpty(val.foodNutritionHistory)) {
            this.foodNutritionHistory = val.foodNutritionHistory
          }
          if (!_.isEmpty(val.bodyCompositionMeasure)) {
            this.bodyCompositionMeasure = val.bodyCompositionMeasure
          }
          if (!_.isEmpty(val.biochemicalData)) {
            this.biochemicalData = val.biochemicalData
          }
          if (!_.isEmpty(val.primaryNutritionDiagnosis)) {
            this.primaryNutritionDiagnosis = val.primaryNutritionDiagnosis
          }
          if (!_.isEmpty(val.nutritionIntervention)) {
            this.nutritionIntervention = val.nutritionIntervention
          }
          if (!_.isEmpty(val.nutritionMonitoring)) {
            this.nutritionMonitoring = val.nutritionMonitoring
          }

          if (!_.isEmpty(val.mealPlan)) {
            this.calorieDeficit = val.mealPlan.calorieDeficit
          }

          if (!_.isEmpty(val.questionnaire)) {
            val.profile.firstName = val.questionnaire.firstName
            val.profile.lastName = val.questionnaire.lastName
            val.profile.age = val.questionnaire.age
          }

          if (!this.id && !_.isEmpty(val.questionnaire)) {
            this.nutritionAssessment.weight = val.questionnaire.weight
            this.nutritionAssessment.height = val.questionnaire.height
            this.physicalActivityHistory.excercise = val.questionnaire.excercise
            this.physicalActivityHistory.weekdaysTypicalActivity =
              val.questionnaire.weekdaysTypicalActivity
            this.physicalActivityHistory.weekendTypicalActivity =
              val.questionnaire.weekendTypicalActivity
            this.foodNutritionHistory.foodPreference =
              val.questionnaire.foodPreference
            this.foodNutritionHistory.foodAllergies =
              val.questionnaire.foodAlergies
            this.foodNutritionHistory.fluidConsumption =
              val.questionnaire.fluidConsumption
            this.foodNutritionHistory.personServeFood =
              val.questionnaire.personServeFood
            this.bodyCompositionMeasure.waistSize = val.questionnaire.waistSize
            this.bodyCompositionMeasure.fat = val.questionnaire.fat
            this.bodyCompositionMeasure.visceralFat =
              val.questionnaire.visceralFat
            this.bodyCompositionMeasure.muscleMass =
              val.questionnaire.muscleMass
          }
        }
      },
    },
  },
  methods: {
    ...mapActions({
      setAdimeNotes: "nutriAdimeNote/setAdimeNotes",
      editAdimeNotes: "nutriAdimeNote/updateAdimeNotes",
      getAdimeNotesById: "nutriAdimeNote/getAdimeNotesById",
      getAdimeNotesDraft: "nutriAdimeNote/getAdimeNotesDraft",
      getClient: "clients/getClientProfileById",
    }),
    nextForm() {
      let params = {
        isDraft: this.isDraft,
        profile: this.profile,
        nutritionAssessment: this.nutritionAssessment,
        physicalActivityHistory: this.physicalActivityHistory,
        calorieMacroNutrientIntake: this.calorieMacroNutrientIntake,
        foodNutritionHistory: this.foodNutritionHistory,
        bodyCompositionMeasure: this.bodyCompositionMeasure,
        biochemicalData: this.biochemicalData,
        primaryNutritionDiagnosis: this.primaryNutritionDiagnosis,
        nutritionIntervention: this.nutritionIntervention,
        nutritionMonitoring: this.nutritionMonitoring,
      }
      if (this.id) {
        params.isDraft = false
        return this.editAdimeNotes({
          clientId: this.clientId,
          params,
        })
          .then(() => {
            if (this.stepIndex < this.steps.length - 1) {
              this.stepIndex++
            } else {
              this.isSuccess = true
            }
          })
          .catch((err) => {
            return this.$toast({
              title: "Failed",
              description: err.data.message,
              status: "error",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            })
          })
      }
      if (this.stepIndex < this.steps.length - 1) {
        params.isDraft = true
        this.setAdimeNotes({
          clientId: this.clientId,
          params,
        })
          .then(() => {
            this.stepIndex++
          })
          .catch((err) => {
            return this.$toast({
              title: "Failed",
              description: err.data.message,
              status: "error",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            })
          })
      } else {
        this.setAdimeNotes({
          clientId: this.clientId,
          params,
        })
          .then(() => {
            this.isSuccess = true
          })
          .catch((err) => {
            return this.$toast({
              title: "Failed",
              description: err.data.message,
              status: "error",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            })
          })
      }
    },
  },
  async mounted() {
    let resp = await this.$store.dispatch(
      "kuisioner/getV2NutriAnswers",
      this.programId
    )
    this.client[this.clientId].gender = resp.generalData.gender

    this.getClient(this.clientId)
    if (this.id) {
      this.getAdimeNotesById(this.id)
    }
    this.getAdimeNotesDraft(this.clientId)
  },
}
</script>
