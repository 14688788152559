<template>
  <c-box>
    <c-checkbox-group
      variant-color="green"
      :default-value="value.checkParts"
      v-model="value.checkParts"
    >
      <c-checkbox
        v-for="(item, index) in checkParts"
        :key="index"
        w="100%"
        mb="16px"
        font-size="18px"
        line-height="27px"
        variant-color="brand"
        :value="item"
      >
        {{ item }}
      </c-checkbox>
    </c-checkbox-group>

    <c-form-control mb="18px">
      <c-form-label
        fontSize="16px"
        fontWeigh="400"
        fontFamily="Roboto"
        font-weight="500"
        font-size="16px"
        line-height="24px"
        color="brand.900"
      >
        Tuliskan indikator spesifik yang akan dimonitor & kriterianya
      </c-form-label>
    </c-form-control>

    <table v-chakra w="100%">
      <thead
        v-chakra
        bg="rgba(0, 140, 129, 0.1);"
        color="brand.900"
        text-align="left"
        text-transform="uppercase"
        font-weight="700"
        font-size="12px"
      >
        <tr>
          <th
            v-chakra
            px="20px"
            py="7px"
            border-top-left-radius="12px"
            border-bottom="1px solid #008C81"
          >
            Jenis Indikator
          </th>
          <th
            v-chakra
            px="20px"
            py="7px"
            border="1px solid #008C81"
            border-top="0"
          >
            Kriteria Indikator
          </th>
          <th
            v-chakra
            px="20px"
            py="7px"
            border-top-right-radius="12px"
            border-bottom="1px solid #008C81"
          ></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in value.indicators"
          :key="'indicators' + index"
        >
          <td v-chakra border="1px solid #C4C4C4">
            <c-input
              type="text"
              border="unset"
              placeholder="Masukkan Jenis Indikator"
              height="62px"
              v-model="item.type"
            />
          </td>
          <td v-chakra border="1px solid #C4C4C4">
            <c-input
              type="text"
              border="unset"
              placeholder="Masukkan Kriteria Indikator"
              height="62px"
              v-model="item.criteria"
            />
          </td>
          <td v-chakra border="1px solid #C4C4C4" align="center">
            <c-button
              min-width="auto"
              variant-color="red"
              variant="link"
              @click="value.indicators.splice(index, 1)"
            >
              <c-image
                :src="require('@/assets/icon-trash-line.svg')"
                alt="empty"
              />
            </c-button>
          </td>
        </tr>
      </tbody>
    </table>
    <c-button
      variant-color="brand"
      variant="outline"
      border-radius="0 0 12px 12px"
      h="62px"
      w="100%"
      left-icon="add"
      mb="25px"
      @click="addNutritionMonitoringIndicator"
    >
      Tambah Indikator
    </c-button>
  </c-box>
</template>

<script>
export default {
  name: "NutritionMonitoringForm",
  props: ["value"],
  data() {
    return {
      checkParts: [
        "Asupan Zat Gizi",
        "Antropometri",
        "Durasi & Intensitas Olahraga",
        "Perilaku & Pengetahuan",
        "Komposisi Tubuh",
        "Hasil Lab",
      ],
    }
  },
  methods: {
    addNutritionMonitoringIndicator() {
      this.value.indicators.push({
        type: "",
        criteria: "",
      })
    },
  },
}
</script>