<template>
  <c-box>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Preferensi makanan/Food preference
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Preferensi makanan/Food preference"
          height="62px"
          v-model="value.foodPreference"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Alergi makanan/Food allergies
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Alergi makanan/Food allergies"
          height="62px"
          v-model="value.foodAllergies"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Konsumsi cairan/hari (Termasuk minuman selain air mineral)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Konsumsi cairan/hari (Termasuk minuman selain air mineral)"
          height="62px"
          v-model="value.fluidConsumption"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Penyedia makanan/Food preparation provider
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Penyedia makanan/Food preparation provider"
          height="62px"
          v-model="value.personServeFood"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Catatan tambahan/Additional notes
      </c-form-label>
      <textarea
        rows="8"
        placeholder="Masukkan Catatan tambahan/Additional notes"
        v-chakra="{
          border: '1px solid #C4C4C4',
          padding: '18px',
          width: '100%',
          borderRadius: '6px',
          height: '180px',
          '&:focus, &:focus-visible': {
            outline: 'unset',
          },
        }"
        v-model="value.additionalNote"
      ></textarea>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: "FoodNutritionHistoryForm",
  props: ["value"],
}
</script>