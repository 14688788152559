<template>
  <c-box>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Berat Badan/Weight (kg)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Berat Badan/Weight (kg)"
          height="62px"
          v-model="value.weight"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Tinggi Badan/Height (cm)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Tinggi Badan/Height (cm)"
          height="62px"
          v-model="value.height"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        IMT/BMI (kg/m2):
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan IMT/BMI (kg/m2)"
          height="62px"
          v-model="value.bodyMassIndex"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Status Gizi
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          error-border-color="red.300"
          placeholder="Masukkan Status Gizi"
          height="62px"
          v-model="value.nutritionalStatus"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Berat Badan Ideal (Batas Bawah) / Minimum Ideal Body Weight
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Berat Badan Ideal (Batas Bawah) / Minimum Ideal Body Weight"
          height="62px"
          v-model="value.minIdealWeight"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Berat Badan Ideal (Batas Atas) / Maximum Ideal Body Weight
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Berat Badan Ideal (Batas Atas) / Maximum Ideal Body Weight"
          height="62px"
          v-model="value.maxIdealWeight"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Kebutuhan Energi Bassal (kkal)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Kebutuhan Energi Bassal (kkal)"
          height="62px"
          v-model="value.basalEnergyNeeds"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Kondisi Keibuan
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.maternalCondition"
          placeholder="Kondisi Keibuan"
          height="62px"
        >
          <option value="Tidak ada">Tidak ada</option>
          <option value="Hamil Trimester 1">Hamil Trimester 1</option>
          <option value="Hamil Trimester 2">Hamil Trimester 2</option>
          <option value="Hamil Trimester 3">Hamil Trimester 3</option>
          <option value="Menyusui Bayi 0-6 Bulan">
            Menyusui Bayi 0-6 Bulan
          </option>
          <option value="Menyusui Bayi 7-12 Bulan">
            Menyusui Bayi 7-12 Bulan
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Tambahan kalori kondisi keibuan
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Tambahan kalori kondisi keibuan"
          height="62px"
          v-model="value.maternalCalorieAddition"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Pertahankan Hal Ini / What to Maintain
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          error-border-color="red.300"
          placeholder="Masukkan Pertahankan Hal Ini / What to Maintain"
          height="62px"
          v-model="value.maintain"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Perbaiki Hal InI / What to Improve
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          error-border-color="red.300"
          placeholder="Masukkan Perbaiki Hal InI / What to Improve"
          height="62px"
          v-model="value.improve"
        />
      </c-input-group>
    </c-form-control>
  </c-box>
</template>

<script>
import { calculateKebutuhanEnergi } from "@/utils/calculate-kebutuhan-energi";

export default {
  name: "NutritionAssessmentForm",
  props: ["value", "age", "gender"],
  watch: {
    "value.weight": {
      immediate: true,
      handler(val) {
        let divide = parseFloat(this.value.height) / 100
        let result = parseFloat(val) / (divide * divide)
        this.value.bodyMassIndex = parseFloat(result).toFixed(2)

        val = parseFloat(val)
        let height = parseFloat(this.value.height)
        let age = parseFloat(this.age)
        let res = calculateKebutuhanEnergi({
          gender: this.gender,
          beratBadan: val,
          tinggiBadan: height,
          usia: age,
        })
        this.value.basalEnergyNeeds = res;
      },
    },
    "value.height": {
      immediate: true,
      handler(val) {
        let divide = parseFloat(val) / 100
        let result = parseFloat(this.value.weight) / (divide * divide)
        this.value.bodyMassIndex = parseFloat(result).toFixed(2)

        let resultMinIdealWeight = 18.5 * (divide * divide)
        this.value.minIdealWeight = parseFloat(resultMinIdealWeight).toFixed(2)

        let resultMaxIdealWeight = 24.9 * (divide * divide)
        this.value.maxIdealWeight = parseFloat(resultMaxIdealWeight).toFixed(2)

        val = parseFloat(val)
        let weight = parseFloat(this.value.weight)
        let age = parseFloat(this.age)
        let res = calculateKebutuhanEnergi({
          gender: this.gender,
          beratBadan: weight,
          tinggiBadan: val,
          usia: age,
        })
        this.value.basalEnergyNeeds = res;
      },
    },
    "value.bodyMassIndex": {
      immediate: true,
      handler(val) {
        if (val < 18.5) {
          this.value.nutritionalStatus = "Berat Badan Kurang"
        }
        if (val > 18.5 && val < 22.99) {
          this.value.nutritionalStatus = "Normal"
        }
        if (val > 22.99 && val < 24.99) {
          this.value.nutritionalStatus = "Preobesitas"
        }
        if (val > 24.99 && val < 29.99) {
          this.value.nutritionalStatus = "Obesitas 1"
        }
        if (val > 29.99) {
          this.value.nutritionalStatus = "Obesitas 2"
        }
      },
    },
  },
}
</script>
