<template>
  <c-box>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Nama Depan
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Nama Depan"
          height="62px"
          readonly
          :style="{ backgroundColor: '#F2F2F2 !important' }"
          v-model="value.firstName"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Nama Belakang
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Nama Belakang"
          height="62px"
          readonly
          :style="{ backgroundColor: '#F2F2F2 !important' }"
          v-model="value.lastName"
        />
      </c-input-group>
    </c-form-control>
    <!-- <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Nama Tengah
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Nama Tengah"
          height="62px"
          readonly
          :style="{ backgroundColor: '#F2F2F2 !important' }"
          v-model="value.middleName"
        />
      </c-input-group>
    </c-form-control> -->
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Usia
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          error-border-color="red.300"
          placeholder="Masukkan Usia"
          height="62px"
          readonly
          :style="{ backgroundColor: '#F2F2F2 !important' }"
          v-model="value.age"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Alasan Konsultasi
      </c-form-label>
      <textarea
        rows="8"
        placeholder="Masukkan Alasan Konsultasi"
        v-chakra="{
          border: '1px solid #C4C4C4',
          padding: '18px',
          width: '100%',
          borderRadius: '6px',
          height: '180px',
          '&:focus, &:focus-visible': {
            outline: 'unset',
          },
        }"
        v-model="value.referralReason"
      ></textarea>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Tujuan Meal Plan
      </c-form-label>
      <textarea
        rows="8"
        placeholder="Masukkan Tujuan Meal Plan"
        v-chakra="{
          border: '1px solid #C4C4C4',
          padding: '18px',
          width: '100%',
          borderRadius: '6px',
          height: '180px',
          '&:focus, &:focus-visible': {
            outline: 'unset',
          },
        }"
        v-model="value.mealPlanObjective"
      ></textarea>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: "ProfileForm",
  props: ["value"],
}
</script>