<template>
  <c-box>
    <EnergiForm
      v-if="profileGizi"
      :value="tValue"
      :aktivitas-fisik="profileGizi.physicalActivity"
      :defisit-kalori="profileGizi.calorieDeficit"
      :kebutuhan-energi="profileGizi.basalEnergyNeeds"
      :tambahan-kalori="profileGizi.extraMaternalCalories"
      without-adds
      @input="changeTValue($event)"
    />
    <ProteinForm
      v-if="profileGizi"
      :value="tValue"
      :weight="profileGizi.weight"
      :kebutuhan-asupan-energi="value.energyNeeds"
      without-adds
      @input="changeTValue($event)"
    />
    <LemakForm
      v-if="profileGizi"
      :value="tValue"
      :kebutuhan-asupan-energi="value.energyNeeds"
      without-adds
      @input="changeTValue($event)"
    />
    <KarbohidratForm
      v-if="profileGizi"
      :value="tValue"
      @input="changeTValue($event)"
      :kebutuhan-asupan-energi="+value.energyNeeds"
      :kebutuhan-lemak="+value.fatNeedsConstant"
      :kebutuhan-protein="+value.proteinNeedsConstant"
      without-adds
    />
    <SeratForm
      v-if="profileGizi"
      :value="tValue"
      @input="changeTValue($event)"
      without-adds
    />
  </c-box>
</template>

<script>
import _ from "lodash"
import EnergiForm from "@/views/meal-plan/forms/asupan-gizi-form/energi-form";
import ProteinForm from "@/views/meal-plan/forms/asupan-gizi-form/protein-form";
import LemakForm from "@/views/meal-plan/forms/asupan-gizi-form/lemak-form";
import KarbohidratForm from "@/views/meal-plan/forms/asupan-gizi-form/karbohidrat-form";
import SeratForm from "@/views/meal-plan/forms/asupan-gizi-form/serat-form";
import { mapGetters } from "vuex";

export default {
  name: "CalorieMacroNutrientIntakeForm",
  components: {
    EnergiForm,
    ProteinForm,
    LemakForm,
    KarbohidratForm,
    SeratForm
  },
  props: [
    "value",
    "energyBassal",
    "activityPercentage",
    "additionMaternalCalorie",
    "weight",
    "calorieDeficit",
  ],
  data() {
    return {
      tValue: {},
    }
  },
  computed: {
    ...mapGetters({
      profileGizi: "mealPlan/profileGizi",
    }),
  },
  async mounted() {
    await this.$store.dispatch("mealPlan/getProfileGizi", this.clientId);
  },
  methods: {
    changeTValue(value) {
      this.tValue = {...this.tValue, ...value};
    },
  },
  watch: {
    tValue: {
      handler: _.debounce(function(val) {
        // Energy
        this.value.energyIntake = val.currentEnergyIntake
        this.value.energyNeeds = val.energyIntakeNeeds
        this.value.energyAdequancy = parseFloat(val.energyAdequancy)

        // Protein
        this.value.currentProteinIntake = val.currentProteinIntake
        this.value.proteinNeeds = val.proteinIntakeNeedPerWeight
        this.value.proteinIntakeNeeds = val.proteinIntakeNeeds
        this.value.proteinNeedsConstant = val.proteinNeedsConstant
        this.value.proteinAdequancy = val.proteinAdequancy

        // Fat
        this.value.currentFatIntake = val.currentFatIntake
        this.value.fatNeedsConstant = val.fatNeedsConstant
        this.value.fatIntakeNeeds = val.fatIntakeNeeds
        this.value.fatAdequancy = val.fatAdequancy

        // Carbohydrate
        this.value.currentCarbohydrateIntake = val.currentCarbohydrateIntake
        this.value.carbohydrateIntakeNeeds = val.carbohydrateIntakeNeeds
        this.value.carbohydrateAdequancy = val.carbohydrateAdequancy

        // Fiber
        this.value.fiberIntake = val.currentFiberIntake
        this.value.fiberNeeds = val.fiberIntakeNeeds
        this.value.fiberAdequancy = val.fiberAdequancy

        this.$emit('input', this.value)
      }, 300),
    },
    energyBassal: {
      immediate: true,
      handler(val) {
         let result = parseFloat(val) +
          (parseFloat(val) * parseFloat(this.activityPercentage) / 100) +
          (parseFloat(this.additionMaternalCalorie) - parseFloat(this.calorieDeficit))

        this.value.energyNeeds = parseFloat(result).toFixed(2)
      },
    },
    "value.energyIntake": {
      immediate: true,
      handler(val) {
        let result = parseFloat(val) / parseFloat(this.value.energyNeeds)
        this.value.energyAdequancy = parseFloat(result).toFixed(2)

        let resultCarboIntake = parseFloat(val)
            * (1 - parseFloat(this.value.proteinNeedsConstant) - parseFloat(this.value.fatNeedsConstant))
            / 4
        this.value.carbohydrateIntakeNeeds = parseFloat(resultCarboIntake).toFixed(2)
      },
    },
    "value.fatNeedsConstant": {
      immediate: true,
      handler(val) {
        let resultFatIntakeNeeds = (parseFloat(this.value.energyNeeds) * parseFloat(val)) / 9
        this.value.fatIntakeNeeds = parseFloat(resultFatIntakeNeeds).toFixed(2)

        let resultCarboIntake = parseFloat(this.value.energyIntake) * (1 - parseFloat(this.value.proteinNeedsConstant) - parseFloat(val)) / 4
        this.value.carbohydrateIntakeNeeds = parseFloat(resultCarboIntake).toFixed(2)
      },
    },
    "value.proteinNeedsConstant": {
      immediate: true,
      handler(val) {
        let result = parseFloat(this.value.energyIntake) * (1 - parseFloat(val) - parseFloat(this.value.fatNeedsConstant)) / 4
        this.value.carbohydrateIntakeNeeds = parseFloat(result).toFixed(2)
      }
    },
    "value.proteinNeeds": {
      immediate: true,
      handler(val) {
        let result = parseFloat(val) * parseFloat(this.weight)
        this.value.proteinIntakeNeeds = Math.round(result)
      },
    },
    "value.energyNeeds": {
      immediate: true,
      handler(val) {
        let result = ((parseFloat(this.value.proteinIntakeNeeds) * 4) / parseFloat(val)) * (100/100)
        this.value.proteinNeedsConstant = parseFloat(result).toFixed(2)

        let resultFatIntakeNeeds = (parseFloat(val) * parseFloat(this.value.fatNeedsConstant)) / 9
        this.value.fatIntakeNeeds = parseFloat(resultFatIntakeNeeds).toFixed(2)
      },
    },
    "value.proteinIntakeNeeds": {
      immediate: true,
      handler(val) {
        let result = ((val * 4) / parseFloat(this.value.energyNeeds)) * (100/100)
        this.value.proteinNeedsConstant = parseFloat(result).toFixed(2)

        let resultProteinAdequancy = parseFloat(this.value.currentProteinIntake) / parseFloat(val)
        this.value.proteinAdequancy = parseFloat(resultProteinAdequancy).toFixed(2)
      },
    },
    "value.currentProteinIntake": {
      immediate: true,
      handler(val) {
        let result = parseFloat(val) / parseFloat(this.value.proteinIntakeNeeds)
        this.value.proteinAdequancy = parseFloat(result).toFixed(2)
      },
    },
    "value.currentFatIntake": {
      immediate: true,
      handler(val) {
        let result = parseFloat(val) / parseFloat(this.value.fatIntakeNeeds)
        this.value.fatAdequancy = parseFloat(result).toFixed(2)
      },
    },
    "value.fatIntakeNeeds": {
      immediate: true,
      handler(val) {
        let resultFatAdequancy = parseFloat(this.value.currentFatIntake) / parseFloat(val)
        this.value.fatAdequancy = parseFloat(resultFatAdequancy).toFixed(2)
      },
    },
    "value.carbohydrateIntakeNeeds": {
      immediate: true,
      handler(val) {
        let result = parseFloat(this.value.currentCarbohydrateIntake) / parseFloat(val)
        this.value.carbohydrateAdequancy = parseFloat(result).toFixed(2)
      },
    },
    "value.currentCarbohydrateIntake": {
      immediate: true,
      handler(val) {
        let result = parseFloat(val) / parseFloat(this.value.carbohydrateIntakeNeeds)
        this.value.carbohydrateAdequancy = parseFloat(result).toFixed(2)
      },
    },
    "value.fiberIntake": {
      immediate: true,
      handler(val) {
        let result = parseFloat(val) / parseFloat(this.value.fiberNeeds)
        this.value.fiberAdequancy = parseFloat(result).toFixed(2)
      },
    },
    "value.fiberNeeds": {
      immediate: true,
      handler(val) {
        let result = parseFloat(this.value.fiberIntake) / parseFloat(val)
        this.value.fiberAdequancy = parseFloat(result).toFixed(2)
      },
    },
  },
}
</script>
