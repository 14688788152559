export default {
  steps: [
    {
      title: "PROFILE",
      type: "profile",
    },
    {
      title: "PENGKAJIAN GIZI/NUTRITION ASSESSMENT",
      type: "nutritionAssessment",
    },
    {
      title: "Riwayat Aktivitas Fisik/Physical Activity History",
      type: "physicalActivityHistory",
    },
    {
      title: "Asupan Kalori & Zat Gizi Makro",
      type: "calorieMacroNutrientIntake",
    },
    {
      title: "Riwayat Pola Makan & Gizi",
      type: "foodNutritionHistory",
    },
    {
      title: "Body Composition Measures",
      type: "bodyCompositionMeasure",
    },
    {
      title: "Biochemical Data, Medical Test, Physical Sign, and Client History",
      type: "biochemicalData",
    },
    {
      title: "DIAGNOSIS GIZI UTAMA/PRIMARY NUTRITION DIAGNOSIS",
      type: "primaryNutritionDiagnosis",
    },
    {
      title: "INTERVENSI GIZI/NUTRITION INTERVENTION",
      type: "nutritionIntervention",
    },
    {
      title: "MONITORING & EVALUASI GIZI/NUTRITION MONITORING & EVALUATION",
      type: "nutritionMonitoring",
    },
  ],
};
