var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-box',[_c('c-form-control',{attrs:{"mb":"18px"}},[_c('c-form-label',{attrs:{"fontSize":"14px","color":"#555555","fontWeigh":"400","fontFamily":"Roboto"}},[_vm._v(" Nama Depan ")]),_c('c-input-group',{attrs:{"size":"md"}},[_c('c-input',{style:({ backgroundColor: '#F2F2F2 !important' }),attrs:{"type":"text","min":"0","error-border-color":"red.300","placeholder":"Masukkan Nama Depan","height":"62px","readonly":""},model:{value:(_vm.value.firstName),callback:function ($$v) {_vm.$set(_vm.value, "firstName", $$v)},expression:"value.firstName"}})],1)],1),_c('c-form-control',{attrs:{"mb":"18px"}},[_c('c-form-label',{attrs:{"fontSize":"14px","color":"#555555","fontWeigh":"400","fontFamily":"Roboto"}},[_vm._v(" Nama Belakang ")]),_c('c-input-group',{attrs:{"size":"md"}},[_c('c-input',{style:({ backgroundColor: '#F2F2F2 !important' }),attrs:{"type":"text","min":"0","error-border-color":"red.300","placeholder":"Masukkan Nama Belakang","height":"62px","readonly":""},model:{value:(_vm.value.lastName),callback:function ($$v) {_vm.$set(_vm.value, "lastName", $$v)},expression:"value.lastName"}})],1)],1),_c('c-form-control',{attrs:{"mb":"18px"}},[_c('c-form-label',{attrs:{"fontSize":"14px","color":"#555555","fontWeigh":"400","fontFamily":"Roboto"}},[_vm._v(" Usia ")]),_c('c-input-group',{attrs:{"size":"md"}},[_c('c-input',{style:({ backgroundColor: '#F2F2F2 !important' }),attrs:{"type":"text","error-border-color":"red.300","placeholder":"Masukkan Usia","height":"62px","readonly":""},model:{value:(_vm.value.age),callback:function ($$v) {_vm.$set(_vm.value, "age", $$v)},expression:"value.age"}})],1)],1),_c('c-form-control',{attrs:{"mb":"18px"}},[_c('c-form-label',{attrs:{"fontSize":"14px","color":"#555555","fontWeigh":"400","fontFamily":"Roboto"}},[_vm._v(" Alasan Konsultasi ")]),_c('textarea',{directives:[{name:"chakra",rawName:"v-chakra",value:({
        border: '1px solid #C4C4C4',
        padding: '18px',
        width: '100%',
        borderRadius: '6px',
        height: '180px',
        '&:focus, &:focus-visible': {
          outline: 'unset',
        },
      }),expression:"{\n        border: '1px solid #C4C4C4',\n        padding: '18px',\n        width: '100%',\n        borderRadius: '6px',\n        height: '180px',\n        '&:focus, &:focus-visible': {\n          outline: 'unset',\n        },\n      }"},{name:"model",rawName:"v-model",value:(_vm.value.referralReason),expression:"value.referralReason"}],attrs:{"rows":"8","placeholder":"Masukkan Alasan Konsultasi"},domProps:{"value":(_vm.value.referralReason)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "referralReason", $event.target.value)}}})],1),_c('c-form-control',{attrs:{"mb":"18px"}},[_c('c-form-label',{attrs:{"fontSize":"14px","color":"#555555","fontWeigh":"400","fontFamily":"Roboto"}},[_vm._v(" Tujuan Meal Plan ")]),_c('textarea',{directives:[{name:"chakra",rawName:"v-chakra",value:({
        border: '1px solid #C4C4C4',
        padding: '18px',
        width: '100%',
        borderRadius: '6px',
        height: '180px',
        '&:focus, &:focus-visible': {
          outline: 'unset',
        },
      }),expression:"{\n        border: '1px solid #C4C4C4',\n        padding: '18px',\n        width: '100%',\n        borderRadius: '6px',\n        height: '180px',\n        '&:focus, &:focus-visible': {\n          outline: 'unset',\n        },\n      }"},{name:"model",rawName:"v-model",value:(_vm.value.mealPlanObjective),expression:"value.mealPlanObjective"}],attrs:{"rows":"8","placeholder":"Masukkan Tujuan Meal Plan"},domProps:{"value":(_vm.value.mealPlanObjective)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "mealPlanObjective", $event.target.value)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }